.skeleton-container {

  @mixin skeleton-color($color) {
    color: transparent!important;
    background-color: #{$color}!important;
    animation: skeleton 1s linear alternate infinite!important;
  }

  @mixin skeleton-light {
    @include skeleton-color($color-grey-100)
  }

  @mixin skeleton-dark {
    @include skeleton-color($color-grey-200)
  }

  @mixin skeleton-width($width: 0, $content: "\00a0") {
    display: inline-block!important;
    min-width: #{$width}em!important;
    &:empty::before {
      content: $content
    }
  }

  @mixin skeleton-radius($radius: 4) {
    border-radius: rem($radius)!important;
  }

  @mixin skeleton-border($color: $color-grey-300) {
    border: 1px solid #{$color};
  }

  .skeleton-avatar {
    @include skeleton-light;
    @include skeleton-radius(8);
  }

  .skeleton-icon {
    color: transparent!important;
    fill: $color-grey-100;
    animation: skeleton 1s linear alternate infinite!important;
  }

  .skeleton-text {
    @include skeleton-light;
    @include skeleton-width();
    @include skeleton-radius();
  }

  .skeleton-table-header {
    @include skeleton-dark;
    @include skeleton-width();
    @include skeleton-radius();
  }

  .skeleton-status {
    @include skeleton-light;
    @include skeleton-width(5);
    @include skeleton-radius();
  }

  .skeleton-value {
    @include skeleton-light;
    @include skeleton-width(10);
    @include skeleton-radius();
  }

  .skeleton-name {
    @include skeleton-light;
    @include skeleton-width(20);
    @include skeleton-radius();
  }

  .skeleton-extension {
    @include skeleton-light;
    @include skeleton-radius();
    @include skeleton-width(3);
  }

  .skeleton-dark-text {
    @include skeleton-dark;
    @include skeleton-width($content: "");
    @include skeleton-radius();
  }

  .skeleton-id {
    @include skeleton-dark;
    @include skeleton-width($content: "#XX-9999");
    @include skeleton-radius(8);
  }

  .skeleton-header {
    @include skeleton-dark;
    @include skeleton-width();
    @include skeleton-radius(8);
  }

  .skeleton-big-number-value {
    @include skeleton-dark;
    @include skeleton-width(2);
    @include skeleton-radius(8);
  }

  .skeleton-hide {
    visibility: hidden!important;
  }

  @mixin skeleton-loader {
    display: block!important;
    position: absolute!important;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
  }

  @mixin skeleton-form-field-loader {
    @include skeleton-color($color-white);
    @include skeleton-border();
  }

  .skeleton-loader {
    @include skeleton-loader();
    @include skeleton-color($color-grey-100);
  }

  .skeleton-form-field-loader {
    @include skeleton-loader();
    @include skeleton-form-field-loader();
    @include skeleton-radius(8);
  }

  .skeleton-form-field-checkbox-loader {
    @include skeleton-loader();
    @include skeleton-form-field-loader();
    @include skeleton-radius(4);
  }

  .skeleton-banner-loader {
    @include skeleton-loader();
    @include skeleton-color($color-grey-100);
    @include skeleton-border();
    @include skeleton-radius(12);
  }

  .skeleton-animation {
    @include skeleton-color('transparent');
    @include skeleton-radius(12);
    border: 1px solid $color-grey-300;
  }

  @keyframes skeleton {
    from {
      opacity: 1;
    }
    to {
      opacity: 0.25;
    }
  }

  .is-active {
    border-bottom-color: transparent!important;
  }
}

.skeleton-loader, .skeleton-form-field-loader, .skeleton-form-field-checkbox-loader, .skeleton-banner-loader {
  display: none;
}