.fade-enter {
  opacity: 0;
}
.fade-exit {
  opacity: 1;
}
.fade-enter-active {
  opacity: 1;
}
.fade-exit-active {
  opacity: 0;
}
.fade-enter-active,
.fade-exit-active {
  transition: opacity .3s;
}


.slide-down-enter {
  opacity: 0;
  transform: translateY(10px);
}

.slide-down-enter-active {
  opacity: 1;
  transform: translateY(0);
  transition: opacity 0.5s, transform 0.5s;
}

.slide-down-exit {
  opacity: 1;
  transform: translateY(0);
}

.slide-down-exit-active {
  opacity: 0;
  transform: translateY(10px);
  transition: opacity 0.5s, transform 0.5s;
}
