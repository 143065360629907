@import '@outloud/web-ui/dist/styles/_functions.scss';@import '@outloud/web-ui/dist/styles/_variables.scss';@import '@outloud/web-ui/dist/styles/_mixins.scss';@import './_variables.scss';
.indicator {
  --size: #{rem(32px)};
  --stroke-width: #{rem(6px)};
  --circle-color: #{$color-grey-100};
  --indicator-color: #{$color-violet-600};
  position: relative;
  
  :global {
    .body {
      width: var(--size);
      height: var(--size);

    }
    .svg {
      width: 100%;
      height: 100%;
    }

    circle {
      fill: none;
      stroke-width: var(--stroke-width);
      stroke: var(--circle-color);

      &:nth-child(2) {
        stroke: var(--indicator-color);
        stroke-linecap: round;
        transition: stroke-dasharray 0.3s;
      }
    }
  }
}
