@import '@outloud/web-ui/dist/styles/_functions.scss';@import '@outloud/web-ui/dist/styles/_variables.scss';@import '@outloud/web-ui/dist/styles/_mixins.scss';@import './_variables.scss';
.badge {
  position: relative;
  font-size: rem(12px);
  line-height: rem(15px);
  display: flex;
  align-items: center;
  padding: rem(4px) rem(8px);
  border-radius: 4px;
  --badge-size: #{rem(24px)};
  height: var(--badge-size);

  &:global.variant-square {
    width: var(--badge-size);
    padding: 0;
    justify-content: center;
  }
}