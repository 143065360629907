@import '@outloud/web-ui/dist/styles/_functions.scss';@import '@outloud/web-ui/dist/styles/_variables.scss';@import '@outloud/web-ui/dist/styles/_mixins.scss';@import './_variables.scss';
.dropdown {
  &:global {
    &.is-plain :local .holder {
      background-color: transparent;
      border-radius: 0;
    }

    &.size-lg :local .holder {
      min-width: rem(200px);
      max-width: 100vw;
    }
    &.size-sm :local .holder {
      min-width: rem(130px);
    }
    &.size-xs :local .holder {
      min-width: rem(80px);
    }
  }

  .holder {
    min-width: rem(200px);
    width: 100%;
    background: $color-white;
    box-shadow: 0px 4px 50px rgba(101, 96, 107, 0.2);
    border-radius: 8px;

    .options {
      padding: rem(8px) 0;
    }

    .option {
      width: 100%;
      display: flex;
      align-items: center;
      text-align: left;
      background-color: transparent;
      padding: rem(12px) rem(20px);
      font-size: rem(14px);
      color: $color-grey-900;
      transition: background-color 0.3s, color 0.3s;

      &:hover {
        background-color: $color-grey-100;
      }

      &:global.is-selected {
        // color: @color-blue-2;
      }
      &:global.is-disabled {
        cursor: default;
        color: $color-grey-300;
        background-color: transparent;
      }
    }
  }
}
