body {
  background-color: $color-bg;

  &.is-modals-opened {
    overflow: hidden;
  }
}

button:not(:disabled):not(.is-disabled) {
  cursor: pointer;
}

.container {
  &.size-md {
    --o-size: #{rem(748px)};
  }
}

.col:empty {
  display: none;
}

.flex-column {
  display: flex;
  flex-direction: column;
}

.fullscreen {
  min-height: 100vh;
}

.row.spacing-sm {
  --o-gutter: #{rem(8px)};
}

.row.spacing-m {
  --o-gutter: #{rem(16px)};
}

.vertical-align-top {
  vertical-align: top;
}

.vertical-align-middle {
  vertical-align: middle;
}

.vertical-align-bottom {
  vertical-align: bottom;
}

.ml-auto {
  margin-left: auto;
}

.mr-auto {
  margin-right: auto;
}

.ma-auto {
  margin: auto;
}

.flex.wrap {
  flex-wrap: wrap;
}

.gap-16 {
  gap: rem(16px);
}

.visibility-hidden {
  visibility: hidden;
}

.width-100 {
  width: 100%;
}
