@import '@outloud/web-ui/dist/styles/_functions.scss';@import '@outloud/web-ui/dist/styles/_variables.scss';@import '@outloud/web-ui/dist/styles/_mixins.scss';@import './_variables.scss';
.alert {
  padding: rem(16px);
  display: flex;
  align-items: center;
  background-color: $color-white;
  border-radius: 8px;

  &.is-spaced {
    padding: rem(20px);
  }

  .icon-holder {
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 0 0 auto;
    width: rem(48px);
    height: rem(48px);
    border-radius: 50%;
    color: $color-white;
    margin-right: rem(20px);
  }

  .details {
    flex: 1 1 auto;
    font-size: rem(14px);
  }

  .action {
    color: var(--light-color);
  }

  .close {
    flex: 0 0 auto;
    width: rem(32px);
    height: rem(32px);
    display: flex;
    align-items: center;
    justify-content: center;
    background: none;
    color: $color-grey-500;
    transition: color 0.3s;

    &:hover {
      color: $color-grey-700;
    }
  }

  &:global.type-error {
    background-color: $color-red-400;

    :local .details,
    :local .close {
      color: $color-red-900;
    }
  }

  &:global.type-success {
    background-color: $color-green-50;

    :local .details,
    :local .close {
      color: $color-green-700;
    }
  }

  &:global.type-warning {
    background-color: $color-violet-50;

    :local .details,
    :local .close {
      color: $color-violet-700;
    }
  }

  &:global.type-info {
    background-color: $color-blue-50;

    :local .details,
    :local .close {
      color: $color-blue-700;
    }
  }
}