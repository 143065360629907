@import '@outloud/web-ui/dist/styles/_functions.scss';@import '@outloud/web-ui/dist/styles/_variables.scss';@import '@outloud/web-ui/dist/styles/_mixins.scss';@import './_variables.scss';
.group {
  margin: rem(4px) 0;

  .link {
    border-radius: rem(6px);
    display: flex;
    align-items: center;
    height: rem(36px);
    padding: 0 rem(12px);
    font-size: rem(13px);
    font-weight: 600;
    color: $color-grey-500;

    :global .icon {
      height: rem(20px);
      color: $color-primary-dark;
    }

    &:global {
      &:hover,
      &.link-active,
      &.submenu-active {
        color: $color-white;
        cursor: pointer;

        :global .icon {
          color: $color-primary;
        }
      }

      &.link-active {
        background-color: $color-anthracite-600;
      }
    }
  }

  :global .submenu-active ~ div {
    :local .link {
      padding-left: rem(48px);
    }
  }
}