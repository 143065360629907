@import '@outloud/web-ui/dist/styles/_functions.scss';@import '@outloud/web-ui/dist/styles/_variables.scss';@import '@outloud/web-ui/dist/styles/_mixins.scss';@import './_variables.scss';
.modals {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 250;
  backdrop-filter: blur(5px);

  .bg {
    background: rgba($color-grey-light, 0.3);
    opacity: 1;
    z-index: 3;
  }

  // &.v-enter-active,
  // &.v-leave-active {
  //   transition: opacity 0.5s;
  // }

  // &.v-leave-to,
  // &.v-enter-from {
  //   opacity: 0;
  // }
}