@import '@outloud/web-ui/dist/styles/_functions.scss';@import '@outloud/web-ui/dist/styles/_variables.scss';@import '@outloud/web-ui/dist/styles/_mixins.scss';@import './_variables.scss';
.notifications {
  width: rem(400px);
  max-height: 80vh;
  overflow: auto;

  .header {
    padding: rem(24px);
    flex: 0 0 auto;
    display: flex;
    align-items: center;
    border-bottom: 2px solid $color-grey-100;
  }

  [class~=item]{
    border-bottom: 2px dashed $color-grey-100;
    font-size: rem(13px);
  }

  [class~=empty]{
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  @include media-breakpoint-down('sm') {
    width: calc(100vw - rem(10px));
  }
}

.notifications-button {
  &[class~=has-unread] > button {
    &:before {
      content: '•';
      position: absolute;

      text-align: center;
      left: 0;
      top: 0;
      right: 0;

      line-height: 0;
      color: $color-orange;
      animation-iteration-count: infinite;
      animation-duration: 1.25s;
      animation-name: blink;

      @keyframes blink {
        79% {
          opacity: 1;
        }
        80% {
          opacity: 0;
        }
        99% {
          opacity: 0;
        }
        100% {
          opacity: 1;
        }
      }
    }
  }

  > button {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;

    // color: $color-grey-500;
    height: rem(34px);
    width: rem(34px);
    border-radius: 4px;

    transition: .3s linear;
    transition-property: background-color, color;
    &:hover {
      background-color: $color-grey-100;
    }
  }

  &[class~=is-toggled] > button {
    background-color: $color-grey-100;
  }
}
