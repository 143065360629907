@import '@outloud/web-ui/dist/styles/_functions.scss';@import '@outloud/web-ui/dist/styles/_variables.scss';@import '@outloud/web-ui/dist/styles/_mixins.scss';@import './_variables.scss';
.svg {
  display: inline-block;
  stroke-width: 0;
}

.strokeCurrentColor {
  stroke: currentColor;

  & path {
    stroke: currentColor;
  }
}
