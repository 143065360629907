@import '@outloud/web-ui/dist/styles/_functions.scss';@import '@outloud/web-ui/dist/styles/_variables.scss';@import '@outloud/web-ui/dist/styles/_mixins.scss';@import './_variables.scss';
.sidebar {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 200;
  background-color: $color-anthracite-900;
  color: $color-white;
  width: $size-sidebar;
  height: 100%;
  display: flex;
  flex-direction: column;
  transition: transform .4s;

  &:global.is-toggled {
    transform: translate3d(0, 0, 0);
  }

  [class~=header] {
    padding: rem(32px) rem(24px);
    display: flex;
    align-items: center;
    flex: 0 0 auto;
  }

  [class~=scroll] {
    flex: 1 1 auto;
    overflow: auto;
    scrollbar-color: $color-anthracite-500 transparent;
    scrollbar-width: thin;

    &:hover {
      &::-webkit-scrollbar-thumb {
        background-color: rgba(255, 255, 255, 0.3);
      }
    }

    &::-webkit-scrollbar {
      width: 6px;
      height: 6px;
      margin: 2px;
    }
    &::-webkit-scrollbar-track {
      background: transparent;
      padding: 2px;
    }
    &::-webkit-scrollbar-thumb {
      background-clip: padding-box;
      border-right: 2px solid $color-anthracite-900;
      background-color: rgba(255, 255, 255, 0);
      margin: 2px;
    }
    &::-webkit-scrollbar-thumb:hover {
      background-color: rgba(255, 255, 255, 0.5);
    }
  }

  @include media-breakpoint-down('lg') {
    transform: translate3d(-101%, 0, 0);
  }
}

.cover {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 190;
  background-color: rgba($color-anthracite-900, 0.5);
}