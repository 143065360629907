body {
  font-family: $font;
}

button,
input,
textarea,
select {
  font: inherit;
}

.fs-12 {
  font-size: rem(12px);
}
.fs-14 {
  font-size: rem(14px);
}
.fs-15 {
  font-size: rem(15px);
}
.fs-16 {
  font-size: rem(16px);
}
.fs-18 {
  font-size: rem(18px);
}
.fs-20 {
  font-size: rem(20px);
}
.fs-24 {
  font-size: rem(24px);
}
.fs-30 {
  font-size: rem(30px);
}
.fs-39 {
  font-size: rem(39px);
}

.fw-400 {
  font-weight: 400;
}
.fw-500 {
  font-weight: 500;
}
.fw-600 {
  font-weight: 600;
}
.fw-700 {
  font-weight: 700;
}

.nowrap {
  white-space: nowrap;
}

.underline {
  text-decoration: underline;
}

.pointer {
  cursor: pointer;
}

h1,
.h1 {
  font-size: rem(32px);
  font-weight: 500;
}

h2,
.h2 {
  font-size: rem(28px);
  font-weight: 500;
}

h3,
.h3 {
  font-size: rem(21px);
  font-weight: 500;
}

h4,
.h4 {
  font-size: rem(18px);
  font-weight: 500;
}

h1,
h2,
h3,
h4,
h5 {
  small {
    display: block;
    // color: $color-grey-500;
    font-size: rem(12px);
    font-weight: 400;
    line-height: rem(15px);
  }
}

hr {
  color: $color-grey-200;
  border: 1px dashed currentColor;
  opacity: 0.3;
  height: 1px;
  width: 100%;
  margin: 0;
  padding: 0;

  &.is-solid {
    opacity: 1;
    border-style: solid;
  }
}

.bdb {
  border-bottom: 1px dashed $color-grey-200;
}
.bdb-0 {
  border-bottom: 0;
}
.bdt {
  border-top: 1px dashed $color-grey-200;
}
.bdt-0 {
  border-bottom: 0;
}
.bdl {
  border-left: 1px dashed $color-grey-200;
}
.bdl-0 {
  border-bottom: 0;
}
.bdr {
  border-right: 1px dashed $color-grey-200;
}
.bdr-0 {
  border-bottom: 0;
}
.bda {
  border: 1px dashed $color-grey-200;
}
.bda-0 {
  border-bottom: 0;
}
.bdx {
  border: 1px dashed $color-grey-200;
  border-left: 0;
  border-right: 0;
}
.bdx-0 {
  border-bottom: 0;
}
.bdy {
  border: 1px dashed $color-grey-200;
  border-top: 0;
  border-bottom: 0;
}
.bdy-0 {
  border-bottom: 0;
}

.truncate,
.truncate-2 {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.truncate-2 {
  -webkit-line-clamp: 2;
}

.grey-text {
  color: $color-grey-500;
}

@mixin make-colors($colors) {
  @each $name, $value in $colors {
    .color-#{$name} {
      color: $value !important;
    }

    .color-#{$name}\@hover:hover {
      color: $value !important;
    }

    .bg-#{$name} {
      background-color: $value !important;
    }

    .bg-#{$name}\@hover:hover {
      background-color: $value !important;
    }

    .border-bottom-#{$name} {
      border-bottom: 1px solid $value !important;
    }
  }
}

@include make-colors($colors);
