@import '@outloud/web-ui/dist/styles/_functions.scss';@import '@outloud/web-ui/dist/styles/_variables.scss';@import '@outloud/web-ui/dist/styles/_mixins.scss';@import './_variables.scss';
.navbar {
  position: sticky;
  z-index: 150;
  top: 0;
  height: $size-navbar;
  background-color: $color-white;
  border-bottom: 1px solid $color-grey-100;

  .inner {
    height: 100%;
  }

  .user {
    width: rem(34px);
    height: rem(34px);
  }

  .menuToggle {
    width: rem(44px);
    height: rem(44px);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    @include media-breakpoint-up('lg') {
      display: none;
    }

    .line {
      width: rem(18px);
      height: 2px;
      border-radius: 2px;
      margin: 2px 0;
      background-color: $color-grey-500;
    }
  }
}