@import '@outloud/web-ui/dist/styles/_functions.scss';@import '@outloud/web-ui/dist/styles/_variables.scss';@import '@outloud/web-ui/dist/styles/_mixins.scss';@import './_variables.scss';
.button {
  position: relative;
  display: inline-flex;
  --button-size: #{rem(40px)};
  padding: 0 rem(16px);
  height: var(--button-size);
  font-size: rem(14px);
  font-weight: 600;
  overflow: hidden;
  z-index: 1;
  justify-content: center;

  .inner {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    transition: opacity .2s;
  }

  .spinner {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 3;
    display: block;
    margin: auto;
    width: calc(var(--button-size) * 0.5);
    aspect-ratio: 1 / 1;
    border: 2px solid rgba(0, 0, 0, 30%);
    border-top-color: currentColor;
    border-radius: 50%;
    animation: spinner 1s cubic-bezier(0.66, 0.32, 0.39, 0.72) infinite;
  }

  @keyframes spinner {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }

  &:global.size-sm {
    --button-size: #{rem(28px)};
    padding: 0 rem(12px);
    font-size: rem(12px);
  }

  &:global.size-lg {
    --button-size: #{rem(48px)};
    padding: 0 rem(32px);
  }

  &:global.width-full {
    width: 100%;
  }

  &:disabled {
    opacity: 0.4;
  }

  &:global.is-loading {
    :local .inner {
      opacity: 0;
    }
  }

  &:global.variant-default {
    background-color: $color-secondary;
    border-radius: 8px;
    color: $color-white;

    &:global.size-sm {
      border-radius: 6px;
    }

    &:not(:disabled):hover {
      background-color: $color-secondary-dark;
    }

    &.is-loading {
      background-color: $color-secondary-light;
    }
  }

  &:global.variant-outline {
    border: 1px solid $color-secondary;
    border-radius: 8px;
    color: $color-secondary;

    &:global.size-sm {
      border-radius: 6px;
    }

    &:not(:disabled):hover {
      background-color: $color-secondary;
      color: $color-white;
    }
    &.is-loading {
      background-color: $color-secondary-light;
      color: $color-white;
    }
  }

  &:global.variant-icon {
    width: var(--button-size);
    border-radius: 8px;
    padding: 0;

    :local .inner {
      width: 100%;
      justify-content: center;
    }

    &.size-xs {
      --button-size: #{rem(16px)};
      border-radius: 4px;
    }
  }

  &:global.variant-text {
    color: $color-secondary;
    font-size: rem(14px);
    padding: 0;
    height: auto;

    &:global.size-xs {
      height: auto;
      font-size: rem(12px);
    }

    &:not(:disabled):hover {
      opacity: 0.6;
    }
  }
}