@import '@outloud/web-ui/dist/styles/_functions.scss';@import '@outloud/web-ui/dist/styles/_variables.scss';@import '@outloud/web-ui/dist/styles/_mixins.scss';@import './_variables.scss';
.loader {
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 3px;
  border-radius: 2px;

  &::after {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: $color-black;
    content: '';
    display: block;
    animation: loading 3s infinite cubic-bezier(0.37, 0.63, 0.68, 0.27);

    @keyframes loading {
      0% {
        transform: translate3d(-100%, 0, 0);
        -webkit-transform: translate3d(-100%, 0, 0);
        -moz-transform: translate3d(-100%, 0, 0);
      }
      100% {
        transform: translate3d(100%, 0, 0);
        -webkit-transform: translate3d(100%, 0, 0);
        -moz-transform: translate3d(100%, 0, 0);
      }
    }
  }

  &:global.is-absolute {
    position: absolute;
    top: 0;
    left: 0;
  }
}