@import '@outloud/web-ui/dist/styles/_functions.scss';@import '@outloud/web-ui/dist/styles/_variables.scss';@import '@outloud/web-ui/dist/styles/_mixins.scss';@import './_variables.scss';
.wizard {
  background-color: $color-anthracite-700;
  border-radius: 8px;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;

  .indicator {
    --size: #{rem(64px)};
    --circle-color: #{$color-grey-700};
    --indicator-color: #{$color-violet-100};
    --stroke-width: 4px;
  }

  .btn {
    overflow: visible;
    :global {
      .arrow {
        transition: transform 0.3s;
      }
    }
    &:hover :global {
      .arrow {
        transform: translateX(4px);
      }
    }
  }
}